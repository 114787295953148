<template>
  <v-sheet
    dense
    color="transparent"
    v-bind="$attrs"
    class="d-flex align-item-center fill-height clock"
    height="50"
  >
    <div class="align-self-center mx-auto primary--text">
      <v-row
        no-gutters
        class="text-center flex-nowrap"
        dense
        justify="space-between"
        align="center"
      >
        <div class="d-flex align-center mr-3 justify-center flex-nowrap">
          <h2 class="mx-1 fs-20 fw-900 font-digital">{{ running.hrs }}</h2>
          <h2 class="fs-18 fw-900">:</h2>
          <h2 class="mx-1 fs-20 fw-900 font-digital">{{ running.min }}</h2>
          <h2 class="fs-18 fw-900">:</h2>
          <h2 class="mx-1 fs-20 fw-900 font-digital">{{ running.sec }}</h2>
        </div>

        <v-btn
          @click="startTimer"
          color="primary"
          v-if="canAction && mine && !clock_is_running"
          dark
          icon
        >
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-btn
          @click="stopTimer"
          color="primary"
          v-if="canAction && mine && clock_is_running"
          dark
          icon
        >
          <v-icon>mdi-stop</v-icon>
        </v-btn>
        <v-btn disabled v-if="canAction && !mine" icon>
          <v-icon>mdi-cancel</v-icon>
        </v-btn>
      </v-row>
    </div>
  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  props: {
    owner: { type: Object },
    canAction: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    timeString: { type: String, default: '00:00:00', required: true }
  },
  data: () => ({
    clock: '00:00:00',
    interval: undefined,
    clock_is_running: false,
    hrs: 0,
    min: 0,
    sec: 0
  }),
  computed: {
    ...mapGetters(['user']),
    mine() {
      return this.owner ? this.owner.id === this.user.id : false
    },
    running() {
      let split = this.clock.split(':')
      return {
        hrs: split[0],
        min: split[1],
        sec: split[2]
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  watch: {
    value: {
      handler: function (val) {
        if (val) this.runClock()
        else this.pauseClock()

        this.clock_is_running = val
      },
      immediate: true
    },
    clock_is_running(val) {
      this.$emit('input', val)
    },
    timeString: {
      handler: function (val) {
        this.clock = val
      },
      immediate: true
    }
  },
  created() {
    if (this.mine) {
      this.$store.watch(
        (state) => {
          return this.$store.getters['timers/globalTimerIsRunning']
        },
        (val) => {
          if (val) {
            this.runClock()
          } else {
            this.pauseClock()
          }
        },
        {
          deep: true
        }
      )
    }
  },
  methods: {
    startTimer() {
      this.runClock()
      if (this.mine) {
        this.$store.commit('timers/setGlobalTimerIsRunning', true)
        this.appSnackbar('Timer started.')
      }
    },
    stopTimer() {
      this.pauseClock()
      if (this.mine) {
        this.$store.commit('timers/setGlobalTimerIsRunning', false)
        this.appSnackbar('Timer stopped.')
      }
    },
    get_hours(timeString) {
      return timeString.split(':')[0]
    },
    get_mins(timeString) {
      return timeString.split(':')[1]
    },
    get_secs(timeString) {
      return timeString.split(':')[2]
    },
    pauseClock() {
      clearInterval(this.interval)
      this.clock_is_running = false
    },
    runClock() {
      this.clock_is_running = true
      this.interval = setInterval(this.doClocking, 1000)
    },
    doClocking() {
      let hours = Number(this.get_hours(this.clock))
      let mins = Number(this.get_mins(this.clock))
      let secs = Number(this.get_secs(this.clock))

      if (secs >= 59 && mins >= 59) {
        hours++
        mins = secs = 0
      } else if (secs >= 59) {
        mins++
        secs = 0
      } else {
        secs++
      }
      this.clock = this.set_time_running(hours, mins, secs)
    },
    set_time_running(hours, mins, secs) {
      if (hours.toString().length === 1) hours = '0' + hours
      if (mins.toString().length === 1) mins = '0' + mins
      if (secs.toString().length === 1) secs = '0' + secs
      return `${hours}:${mins}:${secs}`
    }
  }
}
</script>

<style lang="scss" scoped>
.clock {
  .heading-1 {
    font-size: 50px;
  }
  .font-digital {
    font-family: 'Digital-7';
  }
}
</style>
