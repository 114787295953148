var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"timer flex-nowrap my-0 py-0",attrs:{"align":"center","dense":"","justify":"space-between"}},[_c('v-col',{staticClass:"time fs-22 fw-900",class:_vm.timer_started ? "success--text" : ""},[_vm._v(" "+_vm._s(_vm.display)+" ")]),_c('v-col',{staticClass:"time-control"},[(_vm.is_ongoing_previous_date)?_c('v-tooltip',{attrs:{"color":"primary","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"success"}},on),[_vm._v("mdi-clock-end")])]}}],null,false,1263714609)},[_c('span',[_vm._v("Timer is ongoing since "+_vm._s(_vm.last_date_action))])]):(!_vm.timer_started && _vm.can_start_timer)?_c('v-tooltip',{attrs:{"color":"success","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"border-color--primary",attrs:{"loading":_vm.btnloading,"outlined":"","small":"","icon":""},on:{"click":function($event){return _vm.timer_action('start')}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("play_arrow")])],1)]}}])},[_c('span',[_vm._v("Start Timer")])]):(_vm.timer_started && _vm.can_stop_timer)?_c('v-tooltip',{attrs:{"color":"error","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"border-color":"red"},attrs:{"loading":_vm.btnloading,"outlined":"","small":"","icon":""},on:{"click":function($event){return _vm.timer_action('stop')}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("stop")])],1)]}}])},[_c('span',[_vm._v("Stop Timer")])]):(_vm.is_lapsed)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"secondary"}},on),[_vm._v("mdi-lock-clock")])]}}])},[_c('span',[_vm._v("Timer unavailable")])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }